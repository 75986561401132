import React from "react"
import styled from "styled-components"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

const Ul = styled.ul`
  margin: 0;
`

const Ol = styled.ol`
  margin: 0;
`

const Img = styled.img`
  width: 100%;
`

const Hr = styled.div`
  height: 1px;
  background: ${({ theme }) => `${theme.lightBlue}CC`};
  width: 100%;
`

const Span = styled.span`
  font-weight: bold;
  color: ${({ theme: { darkBlue } }) => darkBlue};
`

const Bold = ({ children }) => <Span>{children}</Span>
const Text = ({ children }) => <p>{children}</p>
const UnorderedList = ({ children }) => <Ul>{children}</Ul>
const OrderedList = ({ children }) => <Ol>{children}</Ol>

export const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const anchor = children?.[1]?.props
      return children?.[1] ? (
        <a href={anchor?.href} rel="noopener noreferrer" target="_blank">
          {anchor?.children}
        </a>
      ) : (
        <Text>{children}</Text>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const src = node.data.target.fixed.src
      return (
        <>
          <pre>
            <Img src={src} alt={src} />
          </pre>
        </>
      )
    },
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
    [BLOCKS.HR]: (node, children) => {
      return <Hr>{children}</Hr>
    },
  },
}
